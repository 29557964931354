import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

//Import Images
import JobDetailImage from "../../../assets/images/job-detail.jpg";
import JobImage10 from "../../../assets/images/featured-job/img-10.png";
import { Link } from "react-router-dom";
import BlogSwiper from "../../Blog/BlogDetails/BlogSwiper";
import PopularPost from "../../Blog/BlogGrid/PopularPost";
import BlogText from "../../Blog/BlogGrid/BlogText";
import FloorPlans from "../../Blog/BlogGrid/FloorPlans";

const JobDetailsDescription = ({ data }) => {
  console.log("DAAAAAAATA", data);
  const [gal, setGal] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const postData = {
        pid: data.ID,
      };

      try {
        const response = await fetch(
          "https://app.jimcaaledeals.com/coreapp/properties/api/getSpecificPropertyGallery",
          {
            method: "POST",
            headers: {
              token: "ba413be8-3d95-4bb9-b495-9ff70c809c27",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData), // Make sure to stringify the body payload
          }
        );

        const res = await response.json(); // Assuming the server responds with JSON

        if (response.ok) {
          if (res.status) {
            setGal(res.message); // Adjust according to your API response
          } else {
            setGal({ FILE: "" });
            throw new Error(res.message || "Failed to fetch data");
          }
        } else {
          setGal({ FILE: "" });
          throw new Error(res.message || "Failed to fetch data");
        }
      } catch (error) {
        setGal({ FILE: "" });
        throw new Error("An error occurred while fetching property types");
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect runs only once after the initial render

  return (
    <React.Fragment>
      <div className="">
        <Row>
          <Col md={7}>
            <h5 className="mb-1">{data.NAME}</h5>
            <ul className="list-inline text-muted mb-0">
              <li className="list-inline-item">{data.PROPERTY_TYPE}</li>
              <div className="d-flex gap-3">

{/*               
              <div className="mt-4 d-flex flex-wrap align-items-start gap-1">
              
                    <span className="badge bg-success">
                      {data.ID}
                    </span>
              </div> */}
              <div className="mt-4 d-flex flex-wrap align-items-start gap-1">
                {
                  // Split the TAGS string and use map to render each tag
                  data.TAGS.split(" ").map((tag, index) => (
                    <span key={index} className="badge bg-primary">
                      {tag}
                    </span>
                  ))
                }
              </div>
              </div>
            </ul>
          </Col>
          <Col md={5}>
           
            <div className="d-flex flex-wrap align-items-center gap-2 justify-content-end">
              <h3 className="text-primary">${data.PRICE} - </h3>
              <h3 className="text-primary">${data.YEARLY_TAX_RATE}</h3>
              <p className="mt-2">/ Budget Range</p>
              
           
            </div>
            
          </Col>
        </Row>
      </div>
      <div className="mt-4">
        <BlogSwiper data={gal} />

        {/* <img src={data.BANNER} alt="" className="img-fluid" style={{width:"734px" , height:"334px"}}/>
          <div className="job-details-compnay-profile">
            <img
              src={data.PHOTO}
              alt=""
              className="img-fluid rounded-3 rounded-3"
              style={{width:"180px"}}
            />
          </div> */}
      </div>

      <Card className="job-overview mt-1 shadow-lg">
        <CardBody className="p-4">
          <h6 className="fs-17">Overview</h6>
          
          <Container>
            <Row md="3" sm="2" xs="1">
              {/* {data.PROPERTY_TYPE && (
                <Col>
                  <div className="d-flex mt-4">
                    <i className="uil uil-building icon bg-primary-subtle text-primary"></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-1">Property Type</h6>
                      <p className="text-muted mb-0">{data.PROPERTY_TYPE}</p>
                    </div>
                  </div>
                </Col>
              )} */}
              {data.ID && (
                <Col>
                  <div className="d-flex mt-4">
                    <i className="uil uil-building icon bg-primary-subtle text-primary"></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-1">Property ID</h6>
                      <p className="text-muted mb-0">APRT00{data.ID}</p>
                    </div>
                  </div>
                </Col>
              )}
              {data.PROPERTY_TYPE && (
                <Col>
                  <div className="d-flex mt-4">
                    <i className="uil uil-building icon bg-primary-subtle text-primary"></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-1">Property Type</h6>
                      <p className="text-muted mb-0">{data.PROPERTY_TYPE}</p>
                    </div>
                  </div>
                </Col>
              )}
              {data.BEDROOMS > 0 && (
                <Col>
                  <div className="d-flex mt-4">
                    <i className="uil uil-bed-double icon bg-primary-subtle text-primary"></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-1">Bed Rooms</h6>
                      <p className="text-muted mb-0">{data.BEDROOMS}</p>
                    </div>
                  </div>
                </Col>
              )}
              {data.LIVING_ROOMS >0 && (
                <Col>
                  <div className="d-flex mt-4">
                    <i className="uil uil-home icon bg-primary-subtle text-primary"></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-1">Living Room </h6>
                      <p className="text-muted mb-0">{data.LIVING_ROOMS}</p>
                    </div>
                  </div>
                </Col>
              )}
              {data.BATHROOMS >0 && (
                <Col>
                  <div className="d-flex mt-4">
                    <i className="uil uil-bed-double icon bg-primary-subtle text-primary"></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-1">Bath Rooms</h6>
                      <p className="text-muted mb-0">{data.BATHROOMS}</p>
                    </div>
                  </div>
                </Col>
              )}
              {data.KITCHENS >0 && (
                <Col>
                  <div className="d-flex mt-4">
                    <i className="uil uil-restaurant icon bg-primary-subtle text-primary"></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-1">Kitchen</h6>
                      <p className="text-muted mb-0">{data.KITCHENS}</p>
                    </div>
                  </div>
                </Col>
              )}
              {data.WIDTH_SIZE_M && data.LENGTH_SIZE_M && (
                <Col>
                  <div className="d-flex mt-4">
                    <i className="uil uil-building icon bg-primary-subtle text-primary"></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-1">Size</h6>
                      <p className="text-muted mb-0">{data.WIDTH_SIZE_M}X{data.LENGTH_SIZE_M}</p>
                    </div>
                  </div>
                </Col>
              )}

              {data.FLOORS >0 &&(
                <Col>
                  <div className="d-flex mt-4">
                    <i className="uil uil-building icon bg-primary-subtle text-primary"></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-1">Number of Floors</h6>
                      <p className="text-muted mb-0">{data.FLOORS}</p>
                    </div>
                  </div>
                </Col>
              )}
              {data.APARTMENTS >0 &&(
                <Col>
                  <div className="d-flex mt-4">
                    <i className="uil uil-building icon bg-primary-subtle text-primary"></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-1">Num of Apartments</h6>
                      <p className="text-muted mb-0">{data.APARTMENTS}</p>
                    </div>
                  </div>
                </Col>
              )}
              
            </Row>
          </Container>
        </CardBody>
      </Card>

      <Card className="job-overview mt-4 shadow-lg">
        <CardBody className="p-4">
          <div className="mt-4">
            <h5 className="mb-3">Description</h5>
            <div className="job-detail-desc">
              <p className="text-muted mb-0">{data.DESCRIPTION}</p>
            </div>
          </div>
        </CardBody>
      </Card>

      {data.PROPERTY_TYPE == "Apartments" &&(
      <Card className="job-overview mt-4 border-0 shadow-lg">
        <CardBody className="p-4">
          <div className="mt-5">
            <h5 className="mb-3">Apartment Plans</h5>

                  <FloorPlans data={data} />
              
          </div>
        </CardBody>
      </Card>
)}
      <Card className="job-overview mt-4 shadow-lg">
        <CardBody className="p-4">
          <div className="mt-5">
            <h5 className="mb-3">Amenities</h5>
            <div className="job-detail-desc">
              <p className="text-muted mb-0">
                <div className="mt-4 d-flex flex-wrap align-items-start gap-1">
                  {
                    // Split the TAGS string and use map to render each tag
                    data.AMENITIES.split("#").map((tag, index) => (
                      <span key={index} className="badge bg-success">
                        {tag}
                      </span>
                    ))
                  }
                </div>
              </p>
            </div>
          </div>
        </CardBody>
      </Card>


      
      {data.NEARBYNAME &&(
      <Card className="job-overview mt-4 border-0 shadow-lg">
        <CardBody className="p-4">
          <div className="mt-5">
            <h5 className="mb-3">Near-by Areas</h5>
            <div className="job-detail-desc">
              <p className="text-muted mb-0">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-primary font-weight-bold">Name</th>
                      <th className="text-primary font-weight-bold">Address</th>
                      <th className="text-primary font-weight-bold">
                        Distance
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(() => {
                      if (data.NEARBYNAME) {
                        const name = data.NEARBYNAME.split("#");
                        const add = data.NEARBYLOCATION.split("#");
                        const dis = data.NEARBYDISTANCE.split("#");
                        const tableElements = [];
                        // Use a for loop to iterate over amenities and create table rows
                        for (let i = 0; i < name.length; i++) {
                          tableElements.push(
                            <tr key={i}>
                              <td>{name[i]}</td>
                              <td>{add[i]}</td>
                              <td>{dis[i]} KM</td>
                            </tr>
                          );
                        }

                        return tableElements; // Return the array of table rows
                      }
                    })()}
                  </tbody>
                </table>
              </p>
            </div>
          </div>
        </CardBody>
      </Card>
    )}
      <Card className="job-overview mt-4 border-0 shadow-lg">
        <CardBody className="p-4">
          <div className="mt-5">
            <h5 className="mb-3">Other Projects of this Contractor</h5>

            <Row>
              <Col lg={12} md={12}>
                <div className="blog-post">
                  <BlogText data={data} />
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
     

      {/* <div className="mt-5">
            <h5 className="mb-3">Bussiness Photos</h5>
            <div className="job-detail-desc">
              <p className="text-muted mb-0">
              <div>
              {
                    // Split the TAGS string and use map to render each tag
                    gal.map((item, index) => (
                      <img src={item.FILE} alt="" className="img-fluid"/>
                    ))
                  }
                
              
              </div>
              </p>
            </div>
          </div> */}
    </React.Fragment>
  );
};

export default JobDetailsDescription;
