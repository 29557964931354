import React, { useState } from "react";
// import { div, div } from "reactstrap";
import { Link } from "react-router-dom";
import { Col, Row, Modal, ModalBody, Input, Label, CardBody } from "reactstrap";

const FeaturedLists = ({ data }) => {
  // if (!Object.keys(data).length) {
  //   return <div>Business Not Found</div>;
  // }

  function kFormatter(num) {
    const amount = Math.round(parseInt(num.replace(/[\$,]/g, "")));
    return Math.abs(amount) > 999 ? Math.sign(amount)*((Math.abs(amount)/1000).toFixed(1)) + 'k' : Math.sign(amount)*Math.abs(amount)
}
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);

  console.log("Data Items is here:", data);
  return (
    <React.Fragment>
      <div className="candidate-list">
        <Row>
          {data.length > 0 &&(
            data.map((dataItems, key) => (
              <Col lg={6} md={6} key={key}>
                <div
                  className={
                    dataItems.addclassNameBookmark === true
                      ? "candidate-grid-box bookmark-post card mt-4"
                      : "candidate-grid-box card mt-4"
                  }
                >
                  <CardBody className="p-4">
                   

                    <div className="d-flex mb-4 mt-4">
                      <div className="flex-shrink-0 position-relative">
                      <Link
                          to={`/bussinessDetails?b=${dataItems.ID}`}
                          className="primary-link"
                        >
                         <img
                          src={dataItems.PHOTO}
                          alt=""
                          className="avatar-md rounded"
                          style={{ width: "200px", height: "200px" }}
                        /> 
                        </Link>
                        
                        <span className={dataItems.NAME}>
                          <span className="visually-hidden">active</span>
                        </span>
                      </div>
                      <div className="ms-3 d-flex flex-column justify-content-around">
                        <Link
                          to={`/bussinessDetails?b=${dataItems.ID}`}
                          className="primary-link"
                        >
                          <h5 className="fs-17">{dataItems.NAME}</h5>
                        </Link>

                        <div className="d-flex gap-4 align-items-center">
                          <div className="d-flex">
                            <i className="uil uil-building text-primary"></i>
                            <div className="ms-2">
                              <p className="text-muted ">{dataItems.TAGS}</p>
                            </div>
                          </div>

                          <div className="d-flex">
                            <i className="uil uil-book text-primary"></i>
                            <div className="ms-2">
                              <p className="text-muted ">
                                {dataItems.TAGSs} New
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <i className="uil uil-location-point text-primary"></i>
                          <div className="ms-2">
                            <p className="text-muted mb-2">
                              {dataItems.ADDRESS}
                            </p>
                          </div>
                        </div>
                        {dataItems.BEDROOMS &&(
                        <div className="mx-1">
                          <p className="text-muted mb-2">
                            {dataItems.BEDROOMS} Bedrooms
                          </p>
                        </div>
                        )}
                        <div className="">
                          {/* <span className="badge bg-info-subtle text-info fs-20">
                          {dataItems.PRICE}
                        </span> */}
                          <div className="d-flex align-items-center gap-1 mt-2">
                            {/* <p className="mt-2">from</p> */}
                            <h6 className="text-primary">{kFormatter(dataItems.PRICE)} -</h6> 
                            <h6 className="text-primary">{kFormatter(dataItems.YEARLY_TAX_RATE)}</h6>
                            <p className="mt-2">/ Budget Range</p>
                          </div>
{/* 
                          <Link
                            to={`/bussinessDetails?b=${dataItems.ID}`}
                            // to="#hireNow"
                            onClick={openModal}
                            data-bs-toggle="modal"
                            className="btn btn-primary btn-hover w-20 mt-2"
                          >
                            <i className="mdi mdi-book"></i> See More
                          </Link> */}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </div>
              </Col>
            )))}
        </Row>

        <div
          className="modal fade"
          id="hireNow"
          tabIndex="-1"
          aria-labelledby="hireNow"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <Modal isOpen={modal} toggle={openModal} centered>
              <ModalBody className="p-5">
                <div className="text-center mb-4">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Hire Now
                  </h5>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                  <button
                    type="button"
                    onClick={openModal}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="mb-3">
                  <Label for="namrFormControlInput" className="form-label">
                    Company Name
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="namrFormControlInput"
                    placeholder="Enter your company name"
                  />
                </div>
                <div className="mb-3">
                  <Label for="emailFormControlInput" className="form-label">
                    Email Address
                  </Label>
                  <Input
                    type="email"
                    className="form-control"
                    id="emailFormControlInput"
                    placeholder="Enter your email"
                  />
                </div>
                <div className="mb-4">
                  <Label
                    for="messageFormControlTextarea"
                    className="form-label"
                  >
                    Message
                  </Label>
                  <textarea
                    className="form-control"
                    id="messageFormControlTextarea"
                    rows="4"
                    placeholder="Enter your message"
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-primary w-100">
                  Send Message
                </button>
              </ModalBody>
            </Modal>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FeaturedLists;
