import React, { useEffect, useState } from "react";
import { Col, Row, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import blogImage4 from "../../../assets/images/blog/img-04.jpg";
import blogImage5 from "../../../assets/images/blog/img-05.jpg";
import blogImage6 from "../../../assets/images/blog/img-06.jpg";
import blogImage7 from "../../../assets/images/blog/img-07.jpg";
import blogImage8 from "../../../assets/images/blog/img-08.jpg";
import blogImage9 from "../../../assets/images/blog/img-09.jpg";

const FloorPlans = ({ data }) => {
  
  console.log("Agent ID is: ", data.AGENT)
  const [optionsData, setData] = useState([]);

  const [property, setProperty] = useState([]);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    const postData = {
      
      pid: data.ID,
    };

    try {
      const response = await fetch(
        "https://app.jimcaaledeals.com/coreapp/properties/api/getSpecificPropertyFloorPlan",
        {
          method: "POST",
          headers: {
            token: "ba413be8-3d95-4bb9-b495-9ff70c809c27",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData), // Make sure to stringify the body payload
        }
      );

      const res = await response.json(); // Assuming the server responds with JSON

      if (response.ok) {
        if (res.status) {
          setProperty(res.message); // Adjust according to your API response

          console.log("Options Data:", optionsData);
        } else {
          setProperty({}); // Adjust according to your API response
        }
      } else {
        setProperty({}); // Adjust according to your API response
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array ensures this effect runs only once after the initial render

  // const { location, match } = this.props;

  // const refresh =(id) =>{

  // const bobUrl = location.pathname.replace(match.params.b, id);

  // return bobUrl;
  // }
  return (
    <React.Fragment>
     <table className="table">
                  <thead>
                    <tr>
                      <th className="text-primary font-weight-bold">Apartment Type</th>
                      <th className="text-primary font-weight-bold">Total Rooms</th>
                      <th className="text-primary font-weight-bold">Bedrooms</th>
                      <th className="text-primary font-weight-bold">Living Rooms</th>
                      <th className="text-primary font-weight-bold">Bathrooms</th>
                      <th className="text-primary font-weight-bold">Kitchen</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                  {property.length > 0 &&(
            property.map((floorPlan, key) => (
              <tr className="text-center">
                <td>{floorPlan.FLOOR_NO}</td>
                <td>{floorPlan.ROOMS} Rooms</td>
                <td>{floorPlan.BEDROOMS} Bedrooms</td>
                <td>{floorPlan.LIVING_ROOMS} Living Room</td>
                <td>{floorPlan.BATHROOMS} Bathrooms</td>
                <td>{floorPlan.KITCHEN} Kitchen</td>
              </tr>
              
            ))
          )}
                  </tbody>
                </table>
      

    </React.Fragment>
  );
};

export default FloorPlans;
